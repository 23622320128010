<template>
  <div class="col-12 col-lg-6 c g">
    <div class="card card-body">
        <div v-if="step == 1">
            <div class="form-group">
            <h5 for="">هل الشهادات تحتوي على رقم الهوية باللغة الانجليزية؟</h5>
            <div class="form-check">
                <label class="form-check-label">
                <input type="radio" class="form-check-input" v-model="number" :value="true">
                <h5>نعم٫ الملف يحتوي على رقم الهوية</h5>
              </label>
            </div>
            <div class="form-check">
                <label class="form-check-label">
                <input type="radio" class="form-check-input" v-model="number" :value="false">
                <h5>
                    لا٫ الملف لا يحتوي على رقم الهوية
                </h5>
              </label>
            </div>
            </div>
            <center>
                <img :src="require('@/assets/images/results_steps_1.png')" style="max-width: 100%" class="g border" alt="">
            </center>
            <br>
            <div class="col-12 text-center g">
                <button class="btn btn-primary" @click="step = 3;">التالي <i class="fa fa-arrow-left"></i></button>
            </div>
        </div>
        <div v-if="step == 2">
            <div class="form-group">
            <h5 for="">هل كل طالب له صفحة ام صفحتين؟</h5>
            <select class="form-control" v-model="two">
                <option :value="false">صفحة واحدة لكل طالب</option>
                <option :value="true">صفحتين لكل طالب</option>
            </select>
            </div>
            <div class="col-12 text-center g">
                <button class="btn btn-primary" @click="step = 3;">التالي <i class="fa fa-arrow-left"></i></button>
            </div>
        </div>
        <div v-if="step == 3 && !number">
            <div class="alert alert-warning text-center g">
                <span class="text-dark">لارسال الشهادات التي لا تحتوي على رقم الهوية٫ يرجى ارسالها من داخل نظام نور عبر الاداة</span>
                <br><br>
                <a href="https://youtu.be/DSrmyuws_IQ" target="_blank">لمشاهدة الشرح اضغط هنا</a>
            </div>
        </div>
        <div v-if="step == 3 && number">
            ملف الشهادات PDF
            <br /><br>
            <input
                id="ingredient_file"
                type="file" accept=".pdf" @change="selected = true;"
            />
            <div id="progress-wrp">
                <div class="progress-bar"></div>
                <div class="status">0%</div>
            </div>
            <div class="col-12 text-center g">
                <button class="btn btn-success" v-if="selected" @click="listenFile()">معالجة الملف الآن <i class="fa fa-arrow-left"></i></button>
                <button class="btn btn-success disabled" disabled v-if="!selected">معالجة الملف الآن <i class="fa fa-arrow-left"></i></button>
            </div>
            <hr>
            <div class="alert alert-success text-center g">
                <a :href="require('@/assets/images/results_places.jpeg')">
                    اضغط هنا لمعرفة اماكن استخراج ملف الشهادات
                </a>
            </div>
            <div class="alert alert-warning text-center g">
                <strong style='color: green'>يمكنك ارسال الشهادات مباشرة من داخل نظام نور عبر الاداة بدون الحاجة لتحميل واعادة رفع الملف
                </strong>
            </div>
        </div>
        <div v-if="step == 4 && loading" class="text-center">
            <img :src="require('@/assets/images/results-process.gif')" style="width: 200px">
            <br>
             <img :src="require('@/assets/images/loading.svg')" style="width: 36px">
            جاري المعالجة...
        </div>
        <div v-if="step == 4 && !loading && !success" class="text-center">
            <div class="alert alert-danger text-center g">
                برجاء اعادة المحاولة واذا استمرت المشكلة تواصل مع الدعم الفني.
                <a href="">اضغط هنا لاعادة المحاولة</a>
            </div>
        </div>
        <div v-if="step == 4 && !loading && success">
            <div class="col-12 g">
                <div class="alert alert-success text-center g donesaved dnone">
                    <h4>
                        <i class="fa fa-check"></i>
                        تم الحفظ في سجل العمليات والرابط الموحد
                    </h4>
                </div>
            </div>
            <div class="row" v-if="Object.keys(arr).length > 0">
                <div class="col-12" v-if="view_message">
                    <div class="form-group">
                      <label for="">نص الرسالة المرسلة</label>
                      <textarea class="form-control" v-model="message" rows="3" @change="messageChanged()"></textarea>
                    </div>
                </div>
                <div class="col-12">
                    ارسال الشهادات عبر: <a href="javascript:;" @click="view_message = !view_message">
                        <i class="fa fa-comment"></i>
                        تعديل نص الرسالة المرسلة
                    </a>
                </div>
                <div class="col-12 col-lg-4 g">
                    <button class="btn btn-success btn-block"  @click="send('whatsapp')">
                        <i class="fa fa-whatsapp"></i>
                         واتس تلقائي
                    </button>
                </div>
                <div class="col-12 col-lg-4 g">
                    <button class="btn btn-success btn-block" @click="send('wa')">
                        <i class="fa fa-whatsapp"></i>
                        اداة المتصفح
                    </button>
                </div>
                <div class="col-12 col-lg-4 g">
                    <button class="btn btn-primary btn-block"  @click="send('sms')">
                        <i class="fa fa-mobile"></i>
                        SMS
                    </button>
                </div>
            </div>
            <br>
            <h5>
                <i class="fa fa-files-o"></i>
                الشهادات التي تم العثور عليها في هذا الملف ({{ Object.keys(arr).length }} شهادة)
            </h5>
            <div class="col-12 table-responsive">
                <table class="table table-sm table-bordered table-striped table-hover">
                    <thead>
                        <th>
                            الطالب
                        </th>
                        <th>
                            رقم الهوية
                        </th>
                        <th>
                            الشهادة
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="(student, number) in arr" :key="number">
                            <td>
                                {{ student.name }}
                            </td>
                            <td>
                                {{ number }}
                            </td>
                            <td>
                                <a :href="`${api}/results/${id}/${student.page}.pdf`" target="_blank" class="btn btn-sm btn-primary">
                                    <i class="fa fa-external-link"></i>
                                    عرض
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            number: true,
            two: false,
            step: 1,
            loading: false,
            file: null,
            user: JSON.parse(localStorage.getItem('user')),
            success: false,
            selected: false,
            arr: {},
            api: api,
            view_message: false,
            id: '0',
            x_id: window.location.href?.split("id=")[1] ?? false,
            message: localStorage.getItem("results_message") ?? `شهادة ابنكم {name}\nمن الرابط التالي\n{link}`
        }
    },
    created(){
        if(!checkPer("results")){
            this.$router.push('/NotPermitted')
            return false;
        }
        var g = this;
        if(g.x_id){
            g.getSingle()
        }
    },
    methods: {
        messageChanged(){
            localStorage.setItem('results_message', this.message)
        },
        getSingle(){
            var g = this;
            $.post(api + '/user/results/single', {
                jwt: this.user.jwt,
                id: g.x_id
            }).then(function(r){
                if(r.status != 100){
                    alert(r.response, 200)
                }else{
                    g.success = true;
                    g.step = 4;
                    g.id = r.response.operation_id;
                    g.arr = r.response.students;
                }
            }).fail(function(){
                alert("حدث خطأ", 200)
            })
        },
        listenFile() {
        if(this.number){
            var x = setInterval(() => {
                if($(".donesaved").length){
                    clearInterval(x)
                    setTimeout(() => {
                        $(".donesaved").fadeIn('slow')
                        setTimeout(() => {
                            $(".donesaved").toggle('slow')
                        }, 5000);
                    }, 1000);
                }
            }, 10);
            var g = this;
            var Upload = function (file) {
                this.file = file;
            };

            Upload.prototype.getType = function () {
                return this.file.type;
            };
            Upload.prototype.getSize = function () {
                return this.file.size;
            };
            Upload.prototype.getName = function () {
                return this.file.name;
            };
            Upload.prototype.doUpload = function () {
                var that = this;
                var formData = new FormData();

                // add assoc key values, this will be posts values
                formData.append("file", this.file, this.getName());
                formData.append("jwt", g.user.jwt);

                $.ajax({
                type: "POST",
                url: api + '/user/general/upload-tmp-files',
                xhr: function () {
                    var myXhr = $.ajaxSettings.xhr();
                    if (myXhr.upload) {
                    myXhr.upload.addEventListener(
                        "progress",
                        that.progressHandling,
                        false
                    );
                    }
                    return myXhr;
                },
                success: function (data) {
                    // your callback here
                    $("#progress-wrp").fadeOut("slow");
                    if(data.status != 100){
                       return alert(data.response, 200)
                    }
                    g.file = data.response
                    g.step = 4;
                    g.process()
                },
                error: function (error) {
                    // handle error
                    alert("حدث خطأ");
                },
                async: true,
                data: formData,
                cache: false,
                contentType: false,
                processData: false,
                timeout: 60000,
                });
            };

            Upload.prototype.progressHandling = function (event) {
                var percent = 0;
                var position = event.loaded || event.position;
                var total = event.total;
                var progress_bar_id = "#progress-wrp";
                if (event.lengthComputable) {
                percent = Math.ceil((position / total) * 100);
                }
                // update progressbars classes so it fits your code
                $(progress_bar_id + " .progress-bar").css("width", +percent + "%");
                $(progress_bar_id + " .status").text(percent + "%");
            };
            setTimeout(() => {
                $("#progress-wrp").fadeIn("slow");
                var file = $("#ingredient_file")[0].files[0];
                var upload = new Upload(file);

                // maby check size or type here with upload.getSize() and upload.getType()

                // execute upload
                upload.doUpload();
            }, 100);
            }
        },
        process(){
            var g = this;
            this.loading = true;
            g.success = false;
            g.id = g.file?.split("tmp/")[1].split("_")[1].split(".")[0];
            $.post(api + '/user/results/process', {
                jwt: this.user.jwt,
                file: g.file?.split("tmp/")[1],
                two: g.two
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response, 200)
                }else{
                    g.success = true;
                    g.arr = r.response
                }
            }).fail(function(){
                g.loading = false;
                alert("حدث خطأ", 200)
            })
        },
        send(method){
            var g = this;
            if(!confirm(`متأكد من ارسال ${Object.keys(g.arr).length} شهادة؟`)){return false;}
            var messages = [];
            for (const [number, value] of Object.entries(g.arr)) {
                var link = `${window.location.href.split('/_results')[0]}/result/${g.id}/${number}`
                messages.push({
                    phones: value.phones,
                    message: g.message.replace("{name}", value.name).replace("{link}", `({${link}})`)
                })
            }
            $.post(api + '/user/general/short-links', {
                jwt: this.user.jwt,
                arr: JSON.stringify(messages),
            }).then(function(rx){
                if(method == 'wa'){
                    $("#sendmodalwaarray").val(JSON.stringify(rx.response))
                    $("#sendmodalwatype").val("results")
                    $("#sendWAModal").click()
                }else{
                    $.post(api + '/user/messages/send-arr', {
                        jwt: g.user.jwt,
                        arr: JSON.stringify(rx.response),
                        method: method,
                        type: "results"
                    }).then(function(r){
                        g.loaded = true;
                        if(r.status != 100){
                            alert(r.response, 200)
                        }else{
                            alert("تم الارسال بنجاح٫ يمكنك تصفح ارشيف الرسائل", 100)
                        }
                    }).fail(function(){
                        alert("حدث خطأ", 200)
                    })
                }
            }).fail(function(){
                    alert("حدث خطأ.", 200)
                })
        }
    }
}
</script>

<style scoped>
#progress-wrp {
  border: 1px solid #0099cc;
  padding: 1px;
  position: relative;
  height: 30px;
  border-radius: 3px;
  margin: 10px;
  text-align: left;
  background: #fff;
  box-shadow: inset 1px 3px 6px rgba(0, 0, 0, 0.12);
  display: none;
}

#progress-wrp .progress-bar {
  height: 100%;
  border-radius: 3px;
  background-color: greenyellow;
  width: 0;
  box-shadow: inset 1px 1px 10px rgba(0, 0, 0, 0.11);
}

#progress-wrp .status {
  top: 3px;
  left: 50%;
  position: absolute;
  display: inline-block;
  color: #000000;
}
</style>